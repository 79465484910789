@import '_variables.scss';

@keyframes playerAnimate {
  0% {
    width: var(--currentPosition, 0);
  }
  100% {
    width: 100%;
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.player {
  position: fixed;
  padding: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(17.5px);
  z-index: 9999;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;


  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    white-space: nowrap;

    span {
      color: $primary;
    }

    &.scroll {
      animation: scroll-left 30s linear infinite;
    }
  }

  .inner {
    width: 100%;
    padding: 0 15px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    max-width: 1100px;
    font-size: 40px;
    line-height: 48px;

    button {
      border: 0;
      padding: 0;
      background: 0;
      margin: 0;
      color: $primary;
      cursor: pointer;

      transition: opacity 0.3s ease-in-out;

      &:disabled {
        opacity: 0.35;
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        opacity: 0.5;
      }
    }

    button[class*="bi-play"],
    button[class*="bi-pause"] {
      margin-right: -4px;
    }

    button[class*="bi-skip"] {
      font-size: 26px;
      line-height: 24px;
    }

    button[class*="bi-skip"] {
      &:target {
        --currentPosition: 0;
        --currentDuration: 0s;
      }
    }


    button[class*="bi-play"] {
      & ~ .status {
        .current {
          animation: none;
          width: var(--currentPosition, 0);

        }
      }
    }

    .status {
      width: 100%;
      height: 45px;
      margin: 0 20px;
      border-radius: 20px;
      position: relative;
      transition: all .3s ease-in-out;

      &:before {
        display: block;
        content: '';
        position: absolute;
        height: 12px;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #C5B8C1;
        border-radius: 20px;
      }

      &:active {
        .current {
          animation: none !important;
        }
      }

      &.disabled {
        opacity: 0.35;
        cursor: not-allowed;
        pointer-events: none;

      }

      &:hover {
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;

        }
      }

      .current {
        position: absolute;
        left: 0;
        height: 12px;
        top: 50%;
        transform: translateY(-50%);
        background: $primary;
        border-radius: 20px;
        max-width: 100%;
        width: var(--currentPosition, 0);

        .indicator {
          display: block;
          position: absolute;
          right: -12px;
          top: 50%;
          transform: translateY(-50%);
          background: $primary;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          transition: all .3s ease-in-out;

          &:hover {
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

  .deviceButton {
    position: relative;
    font-size: 30px;
    line-height: 40px;
    margin-left: 10px;
    align-self: center;

    &.disabled {
      opacity: 0.35;
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        cursor: not-allowed !important;
      }
    }

    .popup {
      position: absolute;
      font-size: 16px;
      line-height: 24px;
      bottom: calc(100% + 8px);
      right: -5px;
      color: #FFF;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      background: $primary;

      &.visible {
        display: block;
      }

      i {
        &:hover {
          opacity: 0.5;
        }
      }

      &:after {
        top: 100%;
        right: 12px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-top-color: $primary;
        border-width: 8px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          white-space: nowrap;
          padding: 8px 20px 8px 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          display: flex;
          transition: all .3s ease-in-out;

          &:first-child {
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }

          &:last-child {
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:before {
            content: '';
            font-family: bootstrap-icons, serif !important;
            margin-right: 5px;
            width: 15px;
          }

          &.selected {
            pointer-events: none;

            &:before {
              content: '\F272';

            }
          }

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background: #fff;
            color: $primary;
            cursor: pointer;
          }
        }
      }
    }
  }

  .queuebutton {
    position: relative;

    .e-contextmenu {
      bottom: 100%;
      right: 0;
      overflow: visible;

      &:after {
        display: block;
        content: '';
        top: 100%;
        right: 15px;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #FFFFFF;
        border-width: 5px;
        transition: all .3s ease-in-out;
      }

      button {
        transition: all .3s ease-in-out;
        opacity: 1 !important;
        border-radius: 5px;
      }

      &:hover {
        &:after {
          border-top-color: $primary;
        }

        button {
          background: $primary;
          color: #FFF;
        }
      }
    }
  }

  @media(min-width: 768px) {
    height: 120px;

    .title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: -15px;

      &:empty {
        display: none;
      }
    }
    .inner {
      font-size: 55px;
      line-height: 64px;

      button[class*="bi-skip"] {
        font-size: 46px;
        line-height: 44px;
      }

      button[class*="bi-play"], button[class*="bi-pause"] {
        font-size: 70px;
        line-height: 78px;
      }
    }
    .queuebutton {
      .e-contextmenu {
        &:after {
          right: 23px;
        }
      }
    }
  }
}

.player-help {
  position: absolute;
  bottom: 0;
}