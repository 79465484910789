@import '_variables.scss';

@keyframes short-eq {
  0% {
    height: 8px
  }
  50% {
    height: 4px
  }
  100% {
    height: 8px
  }
}

@keyframes mid-eq {
  0% {
    height: 12px
  }
  50% {
    height: 6px
  }
  100% {
    height: 12px
  }
}

@keyframes tall-eq {
  0% {
    height: 16px
  }
  50% {
    height: 6px
  }
  100% {
    height: 16px
  }
}

.m-tracklisting {
  .e-back {
    text-decoration: none;
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;
    border: none;
    background: none;
    color: $primary;
    transition: all .3s ease-in-out;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  .e-top {
    display: flex;
    margin-top: 20px;
    width: 100%;
    align-items: center;
    position: relative;

    .e-clear {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: $primary;
      font-weight: 700;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .image {
      margin-right: 20px;
      width: 130px;
      position: relative;

      img {
        border-radius: 30px;
        width: 100%;
      }

      .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 101%;
        height: 101%;
        background: rgba(217, 217, 217, 0.8);
        backdrop-filter: blur(2.5px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        line-height: 50px;
        color: $primary;
        opacity: 0;
        transition: all .3s ease-in-out;

        button {
          border: none;
          padding: 0;
          color: $primary;
          margin: 0 3px;
          transition: opacity 0.3s ease-in-out;
          background: none;

          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }

      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }

    .text {
      color: $primary;
      width: calc(100% - 150px);

      h1 {
        margin: 0;
        font-size: 23px;
        line-height: 28px;
      }

      p {
        font-size: 16px;
        line-height: 22px;
        max-width: 650px;
        margin: 0;
        font-weight: 400;
      }
    }
  }

  .e-details {
    margin: 15px 0 30px;

    .e-table {
      width: 100%;

      .table-body {
        .table-row {
          &:hover:not(.isCurrentTrack) {
            cursor: pointer;
            background: #b9002d1c;

            .table-cell {
              &.number {

                svg {
                  &:hover {
                    opacity: 0.8;
                  }
                }

                .play {
                  opacity: 1;
                }

                span {
                  opacity: 0;
                }
              }
            }
          }
        }
      }

      .table-row {
        display: flex;
        border-top: 1px solid $primary;
        padding: 5px 0 5px 35px;
        flex-wrap: wrap;
        position: relative;
        font-weight: 700;
        color: $primary;
        width: 100%;
        z-index: 10;
        transition: all .3s ease-in-out;

        &:focus {
          outline: none;
        }

        &.hasContextMenu {
          background: #b9002d1c;
          z-index: 100;
        }

        &.isCurrentTrack {
          background: $primary;
          color: #FFFFFF;
          border-radius: 5px;

          & + .table-row {
            border-top-color: transparent;
          }

          .table-cell {
            &.number {
              span {
                opacity: 0;
                z-index: -1;
              }

              .eq {
                opacity: 1;
              }
            }
          }

          .e-contextmenu {
            display: none !important;
          }
        }

        &:last-child {
          border-bottom: 1px solid $primary;
        }
      }

      .table-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.number {
          height: 100%;
          width: 30px;
          position: absolute;
          top: 2px;
          left: 0;
          text-align: center;
          padding: 16px 0;

          .eq, .play {
            position: absolute;
            left: 5px;
            z-index: 10;
            opacity: 0;
            padding: 0;
            transition: all .3s ease-in-out;
          }

          .play {
            top: 12px;
            font-size: 22px;
          }

          .eq {
            top: 14px;

            &.paused {
              .eq-bar {
                animation: none;
                height: 1px;
              }
            }

            .eq-bar {
              transform: scale(1, -1) translate(0, -24px);
              margin: 0 auto;
              fill: #FFF;
              animation-duration: 0.5s;
              animation-iteration-count: infinite;
            }

            .eq-bar--1 {
              animation-name: short-eq;
              animation-delay: 0s;
            }

            .eq-bar--2 {
              animation-name: tall-eq;
              animation-delay: 0.17s;
            }

            .eq-bar--3 {
              animation-name: mid-eq;
              animation-delay: 0.34s;
            }
          }

          span {
            transition: all .3s ease-in-out;
          }
        }

        &.song {
          width: 100%;
          font-size: 18px;
          line-height: 24px;
        }

        &.artist {
          padding-right: 10px;
        }

        &.artist, &.album {
          font-weight: 400;
        }
      }

      .table-head {
        display: none;

        .table-row {
          border: none !important;
        }
      }
    }

  }

  @media(min-width: 600px) {
    .e-top {
      flex-direction: row;
      align-items: center;

      .e-back {
        font-size: 30px;
        line-height: 38px;
      }

      .image {
        margin-right: 40px;
        width: 135px;
      }

      .text {
        width: calc(100% - 175px);
        margin: 0;

        p {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    .e-controls {
      margin-top: 5px;
    }

    .e-details {
      margin: 20px 0;
    }
  }
  @media(min-width: 1024px) {
    .e-top {
      margin-top: 40px;

      .image {
        width: 165px;
      }

      .text {
        width: calc(100% - 205px);

        h1 {
          font-size: 2.5rem;
          line-height: 2.5rem;
        }

        p {
          font-size: 28px;
          line-height: 38px;
          margin: 10px 0 0;
        }
      }

    }
    .e-details {
      .e-table {
        font-size: 16px;
        line-height: 26px;

        .table-head {
          display: flex;

        }

        .table-row {
          align-items: center;
          padding: 15px 0 15px 50px;

          &:first-child {
            border-top: 1px solid $primary;
          }
        }

        .table-cell {
          &.number {
            height: auto;
            top: 50%;
            transform: translateY(-50%);
            width: 45px;

            .eq, .play {
              left: 10px;
            }

            .play {
              top: 16px;
            }
          }

          &.song {
            width: 40%;
            font-size: 16px;
            line-height: 26px;
            padding-right: 10px;
          }

          &.artist, &.album {
            font-weight: 700;
            width: calc(30% - 15px);
          }
        }

        .table-row {
          flex-wrap: nowrap;
        }
      }
    }
  }
  @media(min-width: 1300px) {
    .e-details {
      .e-table {
        font-size: 24px;
        line-height: 34px;

        .table-cell {
          &.number {
            .play {
              font-size: 26px
            }
          }

          &.song {
            font-size: 24px;
            line-height: 34px;
          }
        }
      }
    }
  }
}