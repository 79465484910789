.modalcustom{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  padding:0;
  z-index:99999;
  display:none;

  .background{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(217, 217, 217, 0.8);
    z-index:-1;
  }

  &.active{
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .modal-popup{
    width:100%;
    max-width:650px;
    background: #FFFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
    position:relative;
    z-index:10;

    .modal-title{
      background:$primary;
      color:#FFF;
      padding:20px;

      h3{
        margin:0;
        font-size:18px;
        line-height:28px;
        text-align: center;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      }
    }
    .modal-body{
      padding:20px;
      text-align: center;

      .login{
        p{
          max-width:510px;
          font-size:18px;
          line-height:26px;
          font-weight:600;
          margin:0 auto 20px;

          span{
            color:$primary;
          }
        }
        .options{
          a{
            width:100%;
            padding:10px 50px;
            height:65px;
            text-align: center;
            color:#000;
            text-decoration: none;
            display:flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #C1C1C1;
            border-radius: 30px;
            margin:10px 0;
            position:relative;
            font-size:16px;
            line-height:26px;
            font-weight:700;

            &:hover{
              color:$primary;
            }

            .icon{
              position:absolute;
              top:50%;
              transform:translateY(-50%);
              left:20px;
            }
          }
        }

        .smalltext{
          margin-top:20px;
          p{
            font-size:13px;
            line-height:20px;
            color: #8F8F8F;
            margin:0 auto;

            a{
              text-decoration: underline;
              color: #8F8F8F;
            }
          }
        }
      }

    }
  }

  @media(min-width:768px){
    .modal-popup{
      .modal-body{
        padding:30px;
        .login{
          p{
            margin:10px auto 30px;
          }

          .options{
            a{
              font-size:18px;
            }
          }

          .smalltext{
            margin:40px auto 10px;
          }
        }
      }
    }
  }
}