body {
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3 {
  font-weight: 800;
}

/**
 * Utility classes
 */

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extrabold {
  font-weight: 800;
}