@import '_variables.scss';

.listingitem{
  margin-bottom:24px;
  width:calc(4 / 12 * 100%);
  position:relative;

  &.hasContextMenu{
    z-index:50;

  }
  &.new{
    width:100%;
    .inner{
      display:flex;
      align-items: center;

      .artwork{
        width:130px;
        height:130px;
        margin-right:20px;
      }

      .text{
        width:calc(100% - 150px);
      }

      h2, h3{
        color:$primary;
        font-size:23px;
        line-height:32px;
      }
      h2{
        margin:0;
        transition:all .3s ease-in-out;

        &:hover{
          cursor: pointer;
          color:darken($primary, 5%);
        }
      }
      h3{
        margin:0;
        font-weight:300;
      }
    }
  }

  .inner{
    box-sizing: border-box;
    width:100%;

    .artwork{
      width:100%;
      object-fit: cover;
      border-radius:30px;
      animation-duration: 5s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #f6f7f8;
      background: linear-gradient(to right, #888 8%, #aaa 38%, #888 54%);
      background-size: 1000px 640px;
      aspect-ratio: 1/1;
      position:relative;
      &.loaded{
        background:none;
        animation:none;
      }

      @keyframes placeHolderShimmer{
        0%{
          background-position: -468px 0
        }
        100%{
          background-position: 468px 0
        }
      }

      img{
        width:100%;
        height:100%;
        transition:all .3s ease-in-out;
        -webkit-backface-visibility: hidden;
        border-radius:30px;
      }
    }

    .overlay{
      position:absolute;
      left:0;
      top:0;
      width:101%;
      height:101%;
      background: rgba(217, 217, 217, 0.8);
      backdrop-filter: blur(2.5px);
      display:flex;
      align-items: center;
      justify-content: center;
      font-size:30px;
      line-height:34px;
      color:$primary;
      opacity:0;
      transition:all .3s ease-in-out;

      button{
        border:none;
        padding:0;
        color:$primary;
        margin:0 3px;
        transition:opacity 0.3s ease-in-out;
        background:none;

        &:hover{
          cursor:pointer;
          opacity:0.5;
        }
      }
    }

    &:hover{
      .artwork{
        img{
          //filter: blur(2px);
          //transform:scale(1.05);
        }
      }
      .overlay{
        opacity:1;
      }
    }
  }

  @media(min-width:600px){
    width:calc(3 / 12 * 100%);

    &.new{
      width:calc(4 / 12 * 100%);
      .inner{
        display:block;

        .artwork{
          width:100%;
          height:auto;
        }
        .text{
          width:100%;
        }
        h2{
          margin-top:10px;
        }
        h2, h3{
          font-size:18px;
          line-height:28px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .inner{
      .overlay{
        font-size:40px;
        line-height:48px;
      }
    }
  }
  @media(min-width:800px) {

    &.new {
      width: calc(3 / 12 * 100%);
    }
  }
  @media(min-width:1000px) {
    width: calc(2 / 12 * 100%);
    &.new{
      width: calc(2 / 12 * 100%);
    }
  }

  @media(min-width:1300px){
    width:calc(1.71 / 12 * 100%);

    &.new{
      width:calc(1.71 / 12 * 100%);

      .inner{
        h2{
          //margin-top:20px;
        }
        h2, h3{
          font-size:22px;
          line-height:32px;
        }
      }
    }
    .inner{
      .artwork{
        img{
          border-radius:40px;
        }
      }

      .overlay{
        font-size:50px;
        line-height:58px;

        i{
          //margin:0 8px;
        }
      }
    }
  }
}