$primary: #B8012D;
$buttons: #BA022E;
$background: #D9D9D9;

$white: #FFFFFF;
$black: #000000;

/**
 * Bootstrap overrides
 */
$theme-colors: (
  "primary": $primary
);