@import '_variables.scss';
@import "~bootstrap/scss/bootstrap";
@import '_fonts.scss';
@import '_typography.scss';
@import '_modalCustom.scss';

*, html {

  scroll-behavior: auto !important;
}

body {
  background: $background;
  margin: 0;
  padding-top: 60px;
  overflow-x: hidden;

  @media(min-width: 768px) {
    padding-top: 68px;
  }
}

h1 {
  color: $primary;
}

.container-fluid {
  max-width: 1700px;
}

.navbar {
  padding: 0;
  min-height: 60px;
  font-size: 18px;
  line-height: 28px;
  align-items: stretch;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;

  .container-fluid {
    display: flex;
    align-items: stretch;
    max-width: 1800px;
  }

  .nav-link {
    min-height: 60px;
    display: inline-flex;
    align-items: center;
    padding: 10px 15px !important;
    font-size: 16px;
    //min-width:220px;

    &.hidden {
      display: none;
    }

    &:hover {
      background: darken($primary, 5%);
    }

    &.active {
      background: darken($primary, 10%);
    }
  }

  .navbar-brand {
    padding: 0;
    display: inline-flex;
    width: auto;
    order: 2;
    position: relative;
    text-align: center;
    align-items: flex-end;
    justify-content: center;

    span {
      order: 1;
      display: none;
    }
  }

  //.nav-toggle {
  //  position: absolute;
  //  top: 50%;
  //  transform: translateY(-50%);
  //  left: 15px;
  //  z-index: 20;
  //  width:26px;
  //  height:22px;
  //  padding:5px;
  //
  //  span {
  //    transition: all 0.3s ease-in-out;
  //    position: absolute;
  //    left: 0;
  //    height: 2px;
  //    width: 100%;
  //    background: #FFFFFF;
  //    border-radius: 1px;
  //
  //    &:first-child {
  //      top: 0;
  //      width: 100%;
  //    }
  //
  //    &:nth-child(2),
  //    &:nth-child(3) {
  //      top: 50%;
  //      margin-top: -1px;
  //      width: 100%;
  //    }
  //
  //    &:last-child {
  //      bottom: 0;
  //      width: 100%;
  //    }
  //  }
  //
  //  &:hover {
  //    cursor: pointer;
  //    span{
  //      opacity:0.5;
  //    }
  //  }
  //
  //  &.open {
  //    span {
  //      &:first-child {
  //        top: -50%;
  //        opacity: 0;
  //      }
  //
  //      &:nth-child(2),
  //      &:nth-child(3) {
  //        width: 100%;
  //      }
  //
  //      &:nth-child(2) {
  //        transform: translateX(-50%) rotate(-45deg);
  //        left: 50%;
  //      }
  //
  //      &:nth-child(3) {
  //        transform: translateX(-50%) rotate(45deg);
  //        left: 50%;
  //      }
  //
  //      &:last-child {
  //        bottom: -50%;
  //        opacity: 0;
  //      }
  //    }
  //  }
  //  &.collapsed ~ {
  //    .navbar-collapse {
  //      left: -220px;
  //    }
  //
  //    .body-overlay {
  //      display: none;
  //      opacity: 0;
  //    }
  //  }
  //}
  //.navbar-collapse{
  //  position:fixed;
  //  top:60px;
  //  background:$primary;
  //  display:block;
  //  height:calc(100vh - 60px);
  //  left:0;
  //  transition:all .3s ease-in-out;
  //  border-top:2px solid darken($primary, 5%);
  //  box-sizing: border-box;
  //
  //
  //  .login-link{
  //    position:absolute;
  //    bottom:105px;
  //    left:0;
  //  }
  //}
  .login-link {
    border: none;
    background: none;
  }

  .navbar-collapse {
    display: flex !important;
    padding: 0;
    order: 1;
    flex-basis: auto;

    .navbar-nav {
      display: flex;
      flex-direction: row;
    }
  }

  .body-overlay {
    width: 100vw;
    height: calc(100vh - 60px);
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 60px;
    left: 0;
    transition: opacity .3s ease-in-out;
    opacity: 1;
    transition-delay: 2s;
    z-index: -1;
    display: block;
  }

  @media(min-width: 768px) {
    align-items: flex-end;

    .navbar-brand {
      margin-right: 40px;
      width: auto;
      order: 1;
      align-items: center;

      img {
        order: 1;
        margin-right: 10px;
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        align-self: flex-end;
      }

      span {
        order: 2;
        display: block;
      }
    }
    .nav-toggle, .body-overlay {
      display: none !important;
    }

    .nav-link {
      font-size: 18px;
      padding: 20px 30px !important;
    }

    .navbar-nav {
      margin-right: auto;
    }

    //.nav-link{
    //  min-width:0;
    //}
    //
    //.nav-toggle{
    //  &.collapsed ~ .navbar-collapse{
    //    left:auto;
    //  }
    //}
    //
    //.navbar-collapse{
    //  position:relative;
    //  height:auto;
    //  background:none;
    //  top:auto;
    //  left:auto;
    //  border-top:none;
    //
    //  .login-link{
    //    position:relative;
    //    bottom:auto;
    //    left:auto;
    //  }
    //}
  }
}

.blocked {
  width: 100vw;
  height: 100vh;
  background: $primary;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  z-index: 999999;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #FFF;

  img {
    margin-bottom: 10px;
  }

  @media(min-width: 450px) {
    display: none;
  }
}


.listings, .m-error {
  padding-bottom: 0 !important;
  min-height: calc(100vh - 190px);

  @media(min-width: 768px) {
    //padding-bottom:120px!important;
    min-height: calc(100vh - 230px);

  }
}

//FOOTER
footer {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 104px;
  color: #888;

  span {
    display: none;
    margin-left: 8px;
    border-left: 1px solid #aaa;
    padding-left: 5px;

    &.visible {
      display: inline-block;
    }
  }

  a, .btn-link {
    color: #888;
    margin-left: 4px;

    background: none;
    border: none;

    &:hover {
      color: $primary;
    }
  }

  @media(min-width: 768px) {
    padding-bottom: 135px;
  }
}

//Context Menu
.e-contextmenu {
  position: absolute;
  padding: 0;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.5px);
  overflow: hidden;

  button {
    font-size: 16px;
    line-height: 26px;
    padding: 5px 20px !important;
    border: none;
    background: transparent;
    color: $primary;
    transition: all .3s ease-in-out;
    display: block;
    margin: 0;
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;

    button {
      color: #FFF;
      background: $primary;
    }
  }
}


